$(document).ready(function () {
  if (document.getElementById('js-clock')) {
    const zeroPadding = function (num) {
      // 桁数が1桁だったら先頭に0を加えて2桁に調整する
      var ret;
      if (num < 10) { ret = '0' + num; }
      else { ret = num; }
      return ret;
    }

    const showClock = function () {
      var nowTime = new Date();
      var nowYear = zeroPadding(nowTime.getFullYear());
      var nowMonth = zeroPadding(nowTime.getMonth() + 1);
      var nowDate = zeroPadding(nowTime.getDate());
      var nowHour = zeroPadding(nowTime.getHours());
      var nowMin = zeroPadding(nowTime.getMinutes());
      var nowSec = zeroPadding(nowTime.getSeconds());
      var msg = nowYear + '/' + nowMonth + '/' + nowDate + ' ' + nowHour + ':' + nowMin + ':' + nowSec;
      document.getElementById('js-clock').innerHTML = msg;
    }

    setInterval(showClock, 1000);
  }
})
