$(document).on('change', '#select-school-category > select', function () {
  const checkedValue = $(this).val();
  if (checkedValue == '4年制大学' || checkedValue == '大学院') {
    $('#select-school').show();
    $('#select-school > select').show();
    // 入力をリセット
    $('#select-school > select').val('');
    $('#other-school-name > input').val('');
    // 不要なところは隠す
    $('#other-school-name').hide();
    $('#other-school-name > input').hide();
  } else if(checkedValue == '専門' || checkedValue == '短期大学' || checkedValue == 'その他') {
    $('#other-school-name').show();
    $('#other-school-name > input').show();
    // 入力をリセット
    $('#select-school > select').val('');
    $('#other-school-name > input').val('');
    // 不要なところは隠す
    $('#select-school').hide();
    $('#select-school > select').hide();
  } else {
    // 入力をリセット
    $('#select-school > select').val('');
    $('#other-school-name > input').val('');
    // 不要なところは隠す
    $('#select-school').hide();
    $('#select-school > select').hide();
    $('#other-school-name').hide();
    $('#other-school-ame > input').hide();
  }
});
