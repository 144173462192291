/**
 * Import a2hs.js
 */
import AddToHomeScreen from "a2hs.js";

/**
 * Simple (with default config) init
 */
/**
 * Advanced (with your config) init
 */


$(document).ready(function () {
  AddToHomeScreen({
    brandName: "dot.PASS",
    htmlContent: '<p>このアプリに簡単にアクセスするために、<img src="/images/icons/icon__share.png"> を押して<br>「ホーム画面に追加」を選択してください。</p>',
    logoImage: '<img src="/images/icons/icon-384x384.png">'
    // ... see full list of config options below
  });
})