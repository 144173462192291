$(document).on('change', '.js-is-student', function () {
  const elem = '#js-school-area input, #js-school-area select';
  const checkedValue = $(this).val();

  $('#js-school-area').toggle(checkedValue);
  if (checkedValue == 'true') {
    $(elem).prop('disabled', false);
  } else {
    $(elem).prop('disabled', true);
  }
})
