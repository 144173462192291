$(document).ready(function () {
  const schoolCategory = $('#select-school-category > select').val();
  if (schoolCategory === '') {
    $('#select-school').hide();
    $('#select-school > select').hide();
    $('#other-school-name').hide();
    $('#other-school-name > input').hide();
  } else {
    $('#select-school').show();
  }

  const selectSchoolValue = $('#select-school > select').val();
  if (selectSchoolValue === '' && schoolCategory === '4年制大学' || schoolCategory === '大学院'){
    $('#other-school-name').hide();
    $('#other-school-name > input').hide();
  } else if (selectSchoolValue === '' && schoolCategory === '専門' || schoolCategory === '短期大学' || schoolCategory === 'その他')　{
    $('#other-school-name').show();
    $('#other-school-name > input').show();
    $('#select-school').hide();
    $('#select-school > select').hide();
  } else if (selectSchoolValue !== 'その他') {
    $('#other-school-name > input').hide();
  }

  const otherSchoolName = $('#other-school-name > input').val()
  if(otherSchoolName == '九州大学' || otherSchoolName == '福岡大学' || otherSchoolName == '西南学院大学' || otherSchoolName == '九州産業大学') {
    $('#select-school').show();
    $('#select-school > select').show();
    $('#select-school > select').val(otherSchoolName);
    $('#other-school-name').hide();
    $('#other-school-name > input').hide();
    $('#other-school-name > input').val(otherSchoolName);
  } else if (otherSchoolName) {
    $('#select-school').show();
    $('#select-school > select').show();
    $('#select-school > select').val('その他');
    $('#other-school-name').show();
    $('#other-school-name > input').show();
    $('#other-school-name > input').val(otherSchoolName);
  }

  const checkedValue = $('.js-is-student:checked').val();
  if (checkedValue == 'false') {
    $('#js-school-area').hide();
    $('#select-school > select').hide();
    $('#select-school > select').val('');
    $('#other-school-name').hide();
    $('#other-school-name > input').val('');
  }
});
