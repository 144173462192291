$(document).on('change', '.js-prefecture', function () {
  targetElem = $(this).data('target')
  $.ajax({
    url: '/users/cities',
    type: 'GET',
    data: {
      'prefecture_id': $(this).val(),
      'column': $(this).data('column')
    }
  })
  // Ajaxリクエストが成功した時発動
  .done((data) => {
    $(targetElem).html(data)
  })
  // Ajaxリクエストが失敗した時発動
  .fail((data) => {
    alert(data.message);
    console.log(data);
  })
});
