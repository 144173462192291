// セレクトボックスの大学をセレクトしたとき、画面には表示されないが、
// other-school-nameのinputのvalueを"大学名"にする
$(document).on('change', '#select-school > select', function () {
  const checkedValue = $(this).val();
  if (checkedValue === 'その他') {
    $('#other-school-name').show();
    $('#other-school-name > input').show();
    // 入力をリセット
    $('#other-school-name > input').val('');
  } else {
    $('#other-school-name > input').val(checkedValue);
    $('#other-school-name').hide();
    $('#other-school-name > input').hide();
  }
});
